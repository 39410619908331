import { SignIn } from '@clerk/nextjs';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { WSFlex } from '../../components/base-widgets/ws-flex';
import AuthPage, { AuthPagePrefetchedData, getServerSideProps } from '../../components/common/auth-page';
import { RouteUrls } from '../../utils/route-urls';

/**
 * Custom sign-in page using the Clerk components
 */
const SignInPage = (props: AuthPagePrefetchedData): JSX.Element => {
  const searchParams = useSearchParams();

  /*
   * redirect_url is not always getting utilized by Clerk after the sign-up so we need to detect it here and
   * force the settings on the SignIn component. Need to make sure we do this in the sign-up page as well.
   */
  const redirect_url = searchParams.get('redirect_url');
  const signUpUrlWithRedirect = redirect_url ? RouteUrls.signUpPageWithRedirect(redirect_url) : '';

  return (
    <AuthPage prefetchedProps={props} title="Sign in - Whalesync">
      <WSFlex
        direction="column"
        align="center"
        justify="center"
        css={{
          flexBasis: '500px',
          flexGrow: 1,
          flexShrink: 0,
          backgroundColor: 'white',
          padding: '30px',
        }}
      >
        {/* Banner that shows only on narrow windows */}
        <WSFlex
          justify="center"
          css={{
            backgroundColor: '$loginScreenDark',
            padding: '40px',
            marginBottom: '40px',
            display: 'none',
            '@oneColumnSigninPage': {
              display: 'block',
            },
          }}
        >
          <Image src="/assets/whalesync-logo-white.png" alt="whalesync logo" width={286} height={38} />
        </WSFlex>
        <div style={{ width: '400px' }}>
          <style>
            {`
          .sbui-btn-primary {background-color: #008fd7;}
          .sbui-btn-primary:hover {background-color: #000000;}
          .sbui-input:focus {border-color: #008fd7;}
          .sbui-checkbox:hover {border-color: #008fd7;}
          .dark .sbui-checkbox[type=checkbox]:checked, .sbui-checkbox[type=checkbox]:checked {background-color: #008fd7;}
          a.sbui-typography-link {color: #008fd7;}
        `}
          </style>
          {redirect_url ? (
            <SignIn routing="hash" forceRedirectUrl={redirect_url} signUpUrl={signUpUrlWithRedirect} />
          ) : (
            <SignIn routing="hash" />
          )}
        </div>
      </WSFlex>
    </AuthPage>
  );
};

export default SignInPage;
export { getServerSideProps };
